<template>
  <div
    @mouseup.stop="stop()"
    @mousemove.stop="move($event, col)"
    class="me fadein"
  >
    <div
      class="box box-primary layout-row"
      v-for="(row, ir) in layout"
      :key="ir"
    >
      <div class="box-header with-border">
        <div class="box-tools pull-right">
          <span
            class="btn btn-xs"
            style="margin-right:15px"
            v-if="ir > 0"
            :class="isPageBrakeEnabledAt(ir) ? 'btn-active' : ''"
            @click.stop.prevent="enablePageBrakeAt(ir)"
          >
            <i class="fa fa-eject"></i> {{ $t("page_brake") }}
          </span>
          <span class="btn btn-box-tool" @click.stop.prevent="addRow(ir)">
            <i class="fa fa-plus"></i> {{ $tc("new_line") }}
          </span>
          <span
            class="btn btn-box-tool"
            @click.stop.prevent="delRow(ir)"
            :title="$t('remove')"
            v-if="isEnabled"
          >
            <i class="fa fa-times"></i>
          </span>
        </div>
      </div>
      <div class="box-body">
        <table class="table-resizable" border="1" style="">
          <tr>
            <td
              v-for="(col, ic) in row"
              :key="ic * 100"
              v-bind:style="{ width: col.width + '%' }"
            >
              <button
                type="button"
                class="btn btn-box-tool btn-toolbar"
                style="left:5px;"
              >
                {{ $tc("panel", 2) }}
              </button>
              <button
                type="button"
                class="btn btn-box-tool btn-toolbar"
                v-if="isEnabled && row.length > 1"
                @click.stop.prevent="delCol(ir, ic)"
                :title="$t('remove')"
              >
                <i class="fa fa-times"></i>
              </button>
              <div class="cell-size">
                <input
                  type="number"
                  step="1"
                  min="0"
                  max="100"
                  style=""
                  :value="col.width"
                  :disabled="(layout[ir] || []).length <= 1"
                  @input="setColWidth($event, ir, ic)"
                />
                <span>%</span>
              </div>
              <div
                class="cell-separator"
                v-if="isEnabled"
                @mousedown.stop.prevent="start($event, ir, ic)"
              ></div>
              <button
                class="btn btn-xs btn btn-box-tool btn-add-hor-panel"
                v-if="isEnabled && ic == row.length - 1"
                @click.stop.prevent="addPanel(ir, -1)"
              >
                {{ $tc("new") }} {{ $tc("panel") }}
              </button>
              <div class="cell-content" v-if="col.width > 10">
                <template v-if="col.panels.length">
                  <div
                    class="screen-panel"
                    :class="isClipboardPanel(ir, ic, ip) ? 'clipboard' : ''"
                    v-for="(panelName, ip) in col.panels"
                    :key="ip * 200"
                  >
                    <div class="panel-toolbar">
                      <i
                        v-if="hasChanged(ir, ic, ip)"
                        class="fa fa-circle draft-icon draft-icon-pending"
                        :title="$t('unsaved_changes')"
                        aria-hidden="true"
                      ></i>
                      <span
                        class="btn btn-xs clicable"
                        v-if="
                          isEnabled &&
                            !busy &&
                            panelAt(ir, ic, ip) &&
                            panelAt(ir, ic, ip).customizable
                        "
                        :title="
                          $t(
                            screenId < 0 ? 'publish_screen_before_edit' : 'edit'
                          )
                        "
                        @click.stop.prevent="editPanel(ir, ic, ip, true)"
                      >
                        <i class="fa fa-pencil" style="margin-right:3px"></i>
                        <span class="hidden-sm hidden-xs">{{
                          $t("edit_panel")
                        }}</span>
                      </span>
                      <span
                        class="btn btn-xs clicable"
                        style="margin-left:5px"
                        v-if="
                          isEnabled &&
                            !busy &&
                            panelAt(ir, ic, ip) &&
                            panelAt(ir, ic, ip).customizable &&
                            nPanels > 1
                        "
                        :title="
                          $t(
                            screenId < 0
                              ? 'publish_screen_before_edit'
                              : 'expand'
                          )
                        "
                        @click.stop.prevent="editPanel(ir, ic, ip, false)"
                      >
                        <i class="fa fa-th-large" style="margin-right:3px"></i>
                        <span class="hidden-sm hidden-xs hidden-md">{{
                          $t("edit_other_panels")
                        }}</span>
                      </span>
                      <span
                        class="btn btn-xs clicable"
                        :class="
                          isClipboardPanel(ir, ic, ip) ? 'text-orange' : ''
                        "
                        style="margin-left:5px"
                        v-if="
                          isEnabled &&
                            !busy &&
                            panelAt(ir, ic, ip) &&
                            panelAt(ir, ic, ip).customizable
                        "
                        :title="$t('copy', { item: 'panel' })"
                        @click.stop.prevent="copyPanel(ir, ic, ip)"
                      >
                        <i class="fa fa-copy" style="margin-right:3px"></i>
                      </span>
                      <span
                        class="btn btn-xs clicable"
                        style="margin-left:5px"
                        v-if="isEnabled && !busy && canPaste(ir, ic, ip)"
                        :title="
                          $t('paste', { item: 'panel' }) +
                            ' ' +
                            $t('synoptic.panels.' + clipboardTitle)
                        "
                        @click.stop.prevent="pastePanel(ir, ic, ip)"
                      >
                        <i class="fa fa-paste" style="margin-right:3px"></i>
                      </span>
                    </div>
                    <span
                      class="btn btn-xs remove-panel"
                      @click.stop.prevent="delPanel(ir, ic, ip)"
                      :title="$t('remove')"
                      v-if="isEnabled"
                    >
                      <i class="fa fa-times"></i>
                    </span>
                    <!-- panel selection -->
                    <div
                      class="screen-panel-selection dragable"
                      :draggable="isEnabled"
                      @dragstart="handleDragStart($event, ir, ic, ip)"
                      @dragend="handleDragEnd($event)"
                      @dragover="handleDragOver($event)"
                      @dragenter="handleDragEnter($event)"
                      @dragleave="handleDragLeave($event)"
                      @drop="handleDrop($event)"
                      :data-coordinate="`[${ir}, ${ic}, ${ip}]`"
                    >
                      <select
                        :disabled="!isEnabled"
                        @change="setPanel(ir, ic, ip, $event.target.value)"
                        v-if="!waiting"
                        class="fadein"
                      >
                        <option
                          v-if="panelAt(ir, ic, ip)"
                          :value="panelAt(ir, ic, ip).name"
                          selected
                        >
                          {{
                            $t("synoptic.panels." + panelAt(ir, ic, ip).title)
                          }}
                        </option>
                        <option v-else value="" disabled selected>
                          {{ $t("select") }} {{ $tc("panel", 1) }}
                        </option>
                        <template v-for="(item, ipn) in filteredList">
                          <option
                            :value="item.name"
                            :key="ipn * 300"
                            v-if="canShow(ir, ic, ip, item)"
                          >
                            {{ $t("synoptic.panels." + item.title) }}
                          </option>
                        </template>
                      </select>
                    </div>
                  </div>
                  <div class="sector-add-ver-panel">
                    <button
                      v-if="isEnabled"
                      class="btn btn-xs btn btn-box-tool btn-add-ver-panel"
                      @click.stop.prevent="addPanel(ir, ic)"
                    >
                      {{ $tc("new") }} {{ $tc("panel") }}
                    </button>
                  </div>
                </template>
                <div class="screen-panel" style="" v-else>
                  <button
                    class="btn btn-xs btn btn-box-tool"
                    style="right:5px;"
                    @click.stop.prevent="addPanel(ir, ic)"
                  >
                    <i class="fa fa-plus"></i> {{ $tc("new") }}
                    {{ $tc("panel") }}
                  </button>
                </div>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>

    <div class="box box-primary layout-row">
      <div class="box-header with-border">
        <div class="box-tools pull-right">
          <button
            type="button"
            class="btn btn-box-tool"
            @click.stop.prevent="addRow(-1)"
            v-if="isEnabled"
          >
            <i class="fa fa-plus"></i> {{ $tc("new_line") }}
          </button>
        </div>
        <div class="pull-left btn-box-tool no-padding">
          <div v-if="screenId > 0">
            {{ $t("updated_at") }}: {{ updatedAt }}
            <span>
              - {{ $t("version") }}: {{ screen.revision_code || 0 }}
            </span>
          </div>
          <div v-if="template">
            {{ $t("engine_version") }}: {{ template.render_version || 0 }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Panels from "@/assets/dashboard/panels.json";
import { isEqual } from "lodash";

function getDropTarget(e) {
  return e?.target?.dataset?.coordinate
    ? e?.target
    : e?.target?.parentNode?.dataset?.coordinate
    ? e?.target?.parentNode
    : null;
}

function addDragOver(e) {
  let dst = getDropTarget(e);
  if (dst) {
    dst.style.outline = "1px dashed orange";
  }
}

function delDragOver(e) {
  if (e?.target?.dataset?.coordinate) {
    e.target.style.outline = "none";
  } else if (e.target.parentNode?.dataset?.coordinate) {
    e.target.parentNode.style.outline = "none";
  }
}
export default {
  name: "DashboardLayoutEditor",
  components: {},
  data() {
    return {
      resize: false,
      row: undefined,
      col: undefined,
      layout: [],
      filteredList: [],
      waiting: false
    };
  },
  computed: {
    updatedAt() {
      let ts = this?.screen?.updated_at || "";
      return ts ? this.$dt.format(ts) : "";
    },
    busy() {
      // return (
      //   this.$store.getters["isLoading"] ||
      //   this.$store.getters["isEquipmentDataListLoading"] ||
      //   false
      // );
      return false; // does not wait for data list anymore
    },
    isEnabled() {
      if (this?.screen) {
        if (!this?.screen?.public) {
          return true;
        }
      }
      return false;
    },
    screen() {
      return this.screenId != null
        ? this.$store.getters["dashboard/screen"](this.screenId)
        : null;
    },
    panelList() {
      let lst = Panels;
      if ("panels" in this.$root.config) {
        lst = lst.filter(
          (p) => (this.$root.config.panels || []).indexOf(p.name) >= 0
        );
      }
      return lst.sort((a, b) => {
        if (a.name > b.name) return 1;
        if (b.name > a.name) return -1;
        return 0;
      });
    },
    panels() {
      let lst = {};
      for (var ir in this.layout) {
        for (var ic in this.layout[ir]) {
          for (var ip in this.layout[ir][ic].panels) {
            lst[this.layout[ir][ic].panels[ip]] = `${ir}x${ic}x${ip}`;
          }
        }
      }
      return lst;
    },
    nPanels() {
      return (Object.keys(this.panels || {}) || []).length;
    },
    screenId() {
      return this.$store.getters["dashboard/editorScreenId"];
    },
    template() {
      return this.$store.getters["dashboard/editorTemplate"] || null;
    },
    clipboard: {
      set(value) {
        return this.$store.commit("dashboard/SET_CLIPBOARD", value);
      },
      get() {
        return this.$store.getters["dashboard/clipboard"] || null;
      }
    },
    clipboardTitle() {
      let original = Panels.find(
        (item) => this.clipboard.panel.template == item.template.template
      );
      return original?.title || "unknown";
    }
  },
  watch: {
    template: {
      handler(n) {
        if (n && this.waiting) {
          this.waiting = false;
          this.buildList();
        }
      },
      deep: true
    },
    layout: {
      handler(n) {
        if (n) {
          this.waiting = true;
          this.$store.dispatch("dashboard/updateLayout", {
            screenId: this.screenId,
            layout: n,
            panels: this.filteredList
          });
        }
      },
      deep: true
    },
    screenId: {
      handler(n) {
        if (n) {
          this.layout = [];
          this.initLayout();
        }
      },
      immediate: true
    }
  },
  methods: {
    isClipboardPanel(ir, ic, ip) {
      let name = this.layout[ir][ic].panels[ip];
      return (
        name == this?.clipboard?.panel?.name &&
        this?.clipboard?.screenId == this.screenId
      );
    },
    canPaste(ir, ic, ip) {
      if (
        !this.clipboard.panel ||
        this.layout[ir][ic].panels[ip] == this.clipboard.panel.name
      ) {
        return false;
      }
      let original = Panels.find(
        (item) => this.clipboard.panel.template == item.template.template
      );
      let current = this.panelAt(ir, ic, ip);
      if (current && current.template.template == this.clipboard.panel.template)
        return false;

      if (original.singleton) {
        if (this.clipboard.screenId == this.screenId) {
          return false;
        }
        if (
          (this.template.panels || []).find(
            ({ template }) => template == original.template.template
          )
        ) {
          return false;
        }
      }
      return true;
    },
    panelAt(ir, ic, ip) {
      let name = this.layout[ir][ic].panels[ip];
      if (name) {
        let inserted =
          (this.template?.panels || []).find((p) => p.name == name) || null;
        if (inserted) {
          let panel = this.panelList.find(
            (i) => i.template.template == inserted.template
          );
          if (panel) {
            panel = JSON.parse(JSON.stringify(panel));
            panel.name = inserted.name;
            panel.template = JSON.parse(JSON.stringify(inserted));
            return panel;
          }
        }
      }
      return null;
    },
    buildList() {
      let self = this;
      let template = this.template;
      let lst = [];
      if (template) {
        lst = JSON.parse(JSON.stringify(this.panelList || []));
        (lst || []).forEach((panel) => {
          let tlpPanel =
            (template?.panels || []).find((p) => p.name == panel.name) || null;
          if (!tlpPanel) {
            if (!panel.singleton) {
              panel.name = self.$utils.uuid();
              panel.template.name = panel.name;
            }
          }
        });
      }
      lst = lst
        .map((i) => {
          // localized title
          i.lt = this.$t(`synoptic.panels.${i.title}`);
          return i;
        })
        .sort((a, b) => (a.lt > b.lt ? 1 : b.lt > a.lt ? -1 : 0));
      this.$set(this, "filteredList", lst);
    },
    canShow(ir, ic, ip, item) {
      let slotPanel = this.panelAt(ir, ic, ip);
      if (slotPanel) {
        if (slotPanel.template.template == item.template.template) {
          return false; // It avoids to render repeated panel type with different ids
        }
      }
      let panel = this.filteredList.find(
        (i) => i.template.template == item.template.template
      );
      if (!panel.singleton) return true;
      let lastIndex = this.panels[item.name];
      let currIndex = `${ir}x${ic}x${ip}`;
      return !lastIndex || lastIndex == currIndex;
    },
    setPanel(ir, ic, ip, name) {
      let panel;
      let original = (this.panelList || []).find((p) => p.name == name);
      if (original && !original.singleton && name in this.panels) {
        // there is already a panel with name
        name = this.$utils.uuid();
        panel = JSON.parse(JSON.stringify(original));
        panel.name = name;
        panel.template.name = panel.name;
        this.filteredList.push(panel);
      }
      panel = this.filteredList.find((i) => i.name == name);
      if (panel) {
        let layout = JSON.parse(JSON.stringify(this.layout));
        layout[ir][ic].panels[ip] = name;
        this.$set(this, "layout", layout);
      }
    },
    start(event, row, col) {
      let panel = this.panelList.find((p) => !(p.name in this.panels));
      if (!panel) return;
      this.cell = event.target.parentNode;
      this.row = row;
      this.col = col;
      this.xIni = event.pageX;
      this.wIni = event.target.parentNode.getBoundingClientRect().width;
      if (col == this.layout[row].length - 1) {
        this.layout[row].push({
          width: 0,
          panels: [panel.name]
        });
      }
    },
    stop() {
      this.cell = null;
      this.row = undefined;
      this.col = undefined;
      this.xIni = undefined;
      this.wIni = undefined;
    },
    move(e) {
      if (!this.cell || !this.xIni) return;
      let total = this.cell.parentNode.getBoundingClientRect().width;
      let diff = e.pageX - this.xIni;
      let width = this.wIni + diff;
      let widthP = Math.round((width / total) * 10000) / 100;
      let add = this.layout[this.row][this.col].width - widthP;
      let w = widthP;
      this.layout[this.row][this.col].width = w > 0 ? w : 0;
      if (this.col != this.layout[this.row].length - 1) {
        w =
          Math.round((this.layout[this.row][this.col + 1].width + add) * 100) /
          100;
        this.layout[this.row][this.col + 1].width = w > 0 ? w : 0;
      }
    },
    addPanel(row, col) {
      // let panel = this.panelList.find((p) => !(p.name in this.panels));
      // if (!panel) return;
      let layout = JSON.parse(JSON.stringify(this.layout));
      if (col == -1) {
        layout[row].push({ panels: [], width: 0 });
        let len = layout[row].length;
        let width = Math.round((100 / len) * 100) / 100;
        layout[row].forEach((column) => {
          column.width = width;
        });
        col = len - 1;
      }
      //this.layout[row][col].panels.push(panel.name);
      layout[row][col].panels.push("");
      this.$set(this, "layout", layout);
    },
    delPanel(ir, ic, ip) {
      this.layout[ir][ic].panels.splice(ip, 1);
    },
    editPanel(row, col, panel, expand) {
      let panelName = this.layout[row][col].panels[panel];
      if (expand) this.$store.dispatch("dashboard/expand", panelName);
      let path = `/dashboard/screen/edit/${this.screenId}/${panelName}`;
      if (path != this.$route.path) {
        this.$router.push(path);
      }
    },
    copyPanel(ir, ic, ip) {
      let clipboard = this.clipboard;
      if (clipboard?.panel?.name == this.layout[ir][ic].panels[ip]) {
        clipboard.panel = null;
      } else {
        clipboard.panel = JSON.parse(
          JSON.stringify(this.draftPanel(ir, ic, ip))
        );
        clipboard.screenId = this.screenId;
      }
      this.clipboard = clipboard;
    },
    pastePanel(row, col, ip) {
      if (this.clipboard.panel) {
        let panel = JSON.parse(JSON.stringify(this.clipboard.panel));
        let layout = JSON.parse(JSON.stringify(this.layout));
        panel.name = this.$utils.uuid();
        layout[row][col].panels[ip] = panel.name;
        this.template.panels.push(panel);
        this.$set(this, "layout", layout);
        this.buildList();
      }
    },
    delCol(row, col) {
      let width = this.layout[row][col].width;
      let total = 100 - width;
      this.layout[row].splice(col, 1);
      if (this.layout[row].length == 0) {
        this.delRow(row);
      } else if (this.layout[row].length == 1) {
        this.layout[row][0].width = 100;
      } else {
        for (let c in this.layout[row]) {
          this.layout[row][c].width =
            Math.round((this.layout[row][c].width / total) * 10000) / 100;
        }
      }
    },
    delRow(row) {
      this.layout.splice(row, 1);
    },
    addRow(row) {
      this.layout = this.layout || [];
      if (row == -1) {
        this.layout.push([]);
        this.addPanel(this.layout.length - 1, -1);
      } else {
        if (row < this.layout.length) {
          this.layout.splice(row, 0, []);
          this.addPanel(row, -1);
        }
      }
    },
    draftPanel(ir, ic, ip) {
      let panelName = this.layout[ir][ic].panels[ip];
      return (
        this.$store.getters["dashboard/draftPanel"]({
          screenId: this.screenId,
          panelName: panelName
        }) || null
      );
    },
    flatPanel(panel) {
      if (panel) {
        let n = JSON.parse(JSON.stringify(panel));
        delete n.id;
        for (let i in n?.options?.controls || []) {
          delete n?.options?.controls[i].id;
        }
        return n;
      }
      return null;
    },
    hasChanged(row, col, panel) {
      let nObject = this.draftPanel(row, col, panel);
      let template = this.$store.getters["dashboard/template"](this.screenId);
      if (nObject && template) {
        let oObject =
          (template?.panels || []).find((i) => i.name == nObject.name) || null;
        if (oObject) {
          let n = this.flatPanel(nObject); // without functions or ids
          let o = this.flatPanel(oObject); // without functions or ids
          if (!isEqual(n, o)) {
            return true;
          }
        }
      }
      return false;
    },
    initLayout() {
      this.cell = undefined; // not reactive
      this.xIni = undefined; // not reactive
      this.wIni = undefined; // not reactive
      let layout = (this.template && this.template.layout) || [];
      this.buildList();
      if (layout && layout.length) {
        this.layout = JSON.parse(JSON.stringify(layout || []));
      } else {
        this.addRow(-1);
      }
    },
    handleDragStart(e, ir, ic, ip) {
      if (!this.isEnabled) return;
      e.target.style.opacity = "0.4";
      e.dataTransfer.effectAllowed = "move";
      e.dataTransfer.setData("text/plain", JSON.stringify([ir, ic, ip]));
    },
    handleDragEnd(e) {
      if (!this.isEnabled) return;
      e.target.style.opacity = "1";
      delDragOver(e);
    },
    handleDragOver(e) {
      if (!this.isEnabled) return;
      e.preventDefault();
    },
    handleDragEnter(e) {
      if (!this.isEnabled) return;
      addDragOver(e);
    },
    handleDragLeave(e) {
      if (!this.isEnabled || e.target.contains(e.relatedTarget)) return;
      delDragOver(e);
    },
    handleDrop(e) {
      if (!this.isEnabled) return;
      e.target.style.opacity = "1";
      delDragOver(e);
      if (e.dataTransfer.getData("text/plain")) {
        let target = getDropTarget(e);
        if (target) {
          var src = e.dataTransfer.getData("text/plain") || "null";
          var dst = target.dataset.coordinate;
          if (src && dst && src != dst) {
            src = JSON.parse(src);
            dst = JSON.parse(dst);
            if (src && dst) {
              let layout = JSON.parse(JSON.stringify(this.layout));
              let srcPanel = layout[src[0]][src[1]].panels[src[2]];
              let dstPanel = layout[dst[0]][dst[1]].panels[dst[2]];
              layout[src[0]][src[1]].panels[src[2]] = dstPanel;
              layout[dst[0]][dst[1]].panels[dst[2]] = srcPanel;
              this.$set(this, "layout", layout);
            }
          }
        }
      }
      return false;
    },
    setColWidth($event, ir, ic) {
      var cols = this.layout[ir] || [];
      if (cols.length > 1) {
        let width = parseFloat($event.target.value);
        if (width >= 0 && width <= 100) {
          let diff = parseFloat(this.layout[ir][ic].width - width);
          this.layout[ir][ic].width = Math.round(width * 100) / 100;
          if (ic == cols.length - 1) {
            this.layout[ir][ic - 1].width += Math.round(diff * 100) / 100;
          } else {
            this.layout[ir][ic + 1].width += Math.round(diff * 100) / 100;
          }
        }
      }
    },
    isPageBrakeEnabledAt(ir) {
      let layoutConfig = this?.template?.layoutConfig || {
        rowHeight: [],
        pageBreak: [0]
      };
      return layoutConfig.pageBreak.indexOf(ir) >= 0;
    },
    enablePageBrakeAt(ir) {
      let draft = this.$store.getters["dashboard/draft"];
      if (!draft || !draft?.template) return;
      let template = JSON.parse(JSON.stringify(draft?.template));
      template.layoutConfig = template.layoutConfig || {
        rowHeight: [],
        pageBreak: [0]
      };
      let ix = template.layoutConfig.pageBreak.indexOf(ir);
      if (ix >= 0) {
        template.layoutConfig.pageBreak.splice(ix, 1);
      } else {
        template.layoutConfig.pageBreak.push(ir);
        template.layoutConfig.pageBreak = template.layoutConfig.pageBreak.sort();
      }
      this.$store.dispatch("dashboard/saveDraft", {
        screenId: draft.screenId,
        template: template
      });
    }
  },
  beforeDestroy() {
    this.fadeout = true;
  },
  created() {
    this.cell = undefined; // not reactive
    this.xIni = undefined; // not reactive
    this.wIni = undefined; // not reactive
    //this.initLayout();
  }
};
</script>

<style scoped>
.me {
  min-height: 300px;
}

.layout-row {
  margin-bottom: 10px;
}

.layout-row > .box-header {
  padding: 10px;
  margin-bottom: 0px;
}

.layout-row > .box-header > .box-tools {
  position: absolute;
  right: 0px;
  top: -5px;
}
.btn-toolbar {
  position: absolute;
  right: 5px;
  top: -2px;
  z-index: 2;
}

.panel-toolbar {
  position: absolute;
  padding: 0px;
  top: 0px;
  left: 0px;
  z-index: 2;
  text-align: left;
  background-color: transparent;
  height: 26px;
  line-height: 26px;
  padding: 0 5px;
  color: #666;
  white-space: nowrap;
}

.remove-panel {
  position: absolute;
  right: 0;
  margin-right: 5px;
  color: #666;
}

.clicable:hover {
  cursor: pointer;
  opacity: 0.8;
}

.btn-add-hor-panel {
  position: absolute;
  right: -23px;
  top: 50%;
  z-index: 3;
  border: 1px solid #b3b2b2;
  border-width: 1px 1px 0 1px;
  background-color: white;
  color: gray;
  border-radius: 10px 10px 0 0;
  padding: 0 5px 0px 5px;
  transform: rotate(270deg);
}

.sector-add-ver-panel {
  width: 100%;
  margin: -15px 0 50px 0;
  text-align: center;
  line-height: initial;
  position: relative;
  z-index: 3;
}

.btn-add-ver-panel {
  padding: 3px 10px 3px 10px;
  background-color: white;
  border-color: lightgray;
  border-radius: 0 0 10px 10px;
  border-width: 0 1px 1px 1px;
}

.layout-row > .box-body {
  padding: 0 5px 10px 5px;
  margin-top: 0;
}

.table-resizable {
  position: relative;
  border: 1px solid #c3c0c0;
  width: 100%;
  z-index: 0;
}

.table-resizable::after {
  content: "";
  background-image: url(/static/common/images/bootstrap-grid.svg);
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: 1;
}

.table-resizable > tr {
  vertical-align: top;
  z-index: 2;
}

.table-resizable > tr > td {
  position: relative;
  overflow: hidden;
}

.cell-separator {
  position: absolute;
  cursor: e-resize;
  top: 0px;
  bottom: 0px;
  width: 3px;
  right: 0;
  border-width: 0 1px 0 1px;
  border-style: double;
  border-color: darkgray;
  border-radius: 3px;
  z-index: 3;
}

.cell-content {
  line-height: 100px;
  overflow: hidden;
  text-align: center;
  padding-right: 22px;
}

.screen-panel {
  position: relative;
  min-height: 40px;
  width: 96%;
  margin: 40px 2% 15px 2%;
  border-radius: 5px;
  background-color: whitesmoke;
  font-size: 10pt;
  z-index: 2;
  border: 1px solid transparent;
}

.cell-size {
  position: absolute;
  bottom: 0;
  right: 5px;
  font-size: 8pt;
  color: gray;
  font-family: sans-serif;
  text-shadow: 1px 0px lightsalmon;
  z-index: 3;
}

.cell-size > input {
  color: gray;
  text-shadow: 1px 0px lightsalmon;
  border: 1px solid transparent;
  border-radius: 3px;
  width: 40px;
  height: 24px;
  text-align: right;
  padding: 0 4px 0 0;
  line-height: 1em;
}
.cell-size > input:hover {
  cursor: pointer;
}

.cell-size > input:focus {
  border-color: lightgray;
  outline-width: 0;
}

input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.screen-panel .screen-panel-selection {
  overflow: hidden;
}

.over {
  outline-offset: 0px;
  outline-width: 1px;
  outline-color: orange;
  outline-style: dashed;
}

.screen-panel select {
  font-size: 10pt;
  font-family: sans-serif;
  color: #456886;
  border: 0;
  background-color: lightgrey;
  width: 98%;
  padding: 8px 5px;
  margin: 0 1%;
  border-radius: 5px;
}

.clipboard {
  border-color: orange;
}

.screen-panel select:hover {
  color: #5f5e5e;
  opacity: 0.9;
  cursor: pointer;
}

.screen-panel select:focus {
  border: 0;
  box-shadow: 0 0 0 4px var(--focus-ring);
  outline: 0;
}

.draft-icon {
  font-size: 1rem;
  text-align: center;
  vertical-align: middle;
  margin-left: 0.4rem;
}

.draft-icon-pending {
  color: #ecc816;
}

.dragable:hover {
  cursor: move;
}

.fadein {
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s; /* Firefox < 16 */
  -ms-animation: fadein 1s; /* Internet Explorer */
  -o-animation: fadein 1s; /* Opera < 12.1 */
  animation: fadein 1s;
}

.btn-active {
  color: #a94442;
  text-shadow: 0px 1px 1px #8c7e64;
}

.fa-eject {
  vertical-align: middle;
  text-shadow: none;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
} /* Firefox < 16 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
} /* Internet Explorer */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
} /* Opera < 12.1 */
</style>
