<template>
  <div>
    <ProcessAreaSelection
      :value="processArea"
      :allowNull="true"
      :nullLabel="$tc('all', 2)"
      :disabled="disabled"
      :hint="$t('screen_process_area')"
      :inline="true"
      @input="update"
      :key="screenId"
    />
  </div>
</template>

<script>
import ProcessAreaSelection from "@/components/processarea-selection";

import { mapGetters, mapActions } from "vuex";

export default {
  name: "ScreenProcessArea",
  components: {
    ProcessAreaSelection
  },
  props: {
    screenId: {
      type: Number,
      required: true
    },
    value: {
      type: Object,
      required: false,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    template() {
      return this.$store.getters["dashboard/editorTemplate"];
    },
    processArea() {
      if (this.value.id != undefined) return this.value.id;
      else if (this.screenProcessArea != undefined)
        return {
          id: this.screenProcessArea
        };
      else return { id: null };
    },
    screenProcessArea() {
      return this?.template?.draft?.processAreaId || undefined;
    }
  },
  methods: {
    update({ id }) {
      if (!this.disabled)
        this.updateProcessArea({
          screenId: this.screenId,
          processAreaId: id
        });
    },
    ...mapActions("dashboard", ["updateProcessArea"])
  }
};
</script>

<style></style>
