<template>
  <section class="container-fluid">
    <div
      class="box box-warning wrapper-box"
      v-if="$can('manage', 'TelaCustomizadaAcesso')"
    >
      <div id="toolbar" class="toolbar">
        <div class="row">
          <div class="col-lg-2 col-md-3 col-sm-4">
            <label class="hidden-sm hidden-xs">{{ $t("screen_editor") }}</label>
            <div class="icons">
              <span
                class="btn btn-xs"
                @click.stop.prevent="onCreate()"
                :title="$tc('new', 2)"
              >
                <i class="fa fa-plus-square"></i>
              </span>
              <span
                class="btn btn-xs"
                @click.stop.prevent="onImport"
                :title="$tc('import')"
              >
                <i class="fa fa-upload"></i>
              </span>
              <DashboardDownloadBtn
                v-if="currentScreen"
                :screenId="selectedScreen"
              />
              <span
                class="btn btn-xs"
                v-if="isEnabled && parseInt(this.selectedScreen) > 0"
                @click.stop.prevent="onRestore()"
                :title="$tc('restore')"
              >
                <i class="fa fa-undo"></i>
              </span>
              <span
                class="btn btn-xs"
                v-if="isEnabled && $can('manage', 'TelaCustomizadaEscrita')"
                @click.stop.prevent="onPublish(currentScreen)"
                :title="$tc('publish')"
              >
                <i class="fa fa-cloud-upload"></i>
              </span>
              <span
                class="btn btn-xs"
                v-if="parseInt(this.selectedScreen) > 0 && currentScreen"
                @click.stop.prevent="onSaveAs(currentScreen)"
                :title="$t('clone')"
              >
                <i class="fa fa-copy"></i>
              </span>
              <span
                v-if="parseInt(this.selectedScreen) > 0 && currentScreen"
                class="btn btn-xs"
                @click.stop.prevent="onOpen()"
                :title="$t('view')"
              >
                <i class="fa fa-dashboard"></i>
              </span>
            </div>
          </div>
          <div
            class="col-lg-10 col-md-9 col-sm-8"
            style="
              display: flex;
              justify-content: flex-start;
              align-items: center;
            "
            v-if="!busy && currentScreen && screensTemplates[selectedScreen]"
          >
            <span
              id="old-version-alert"
              v-if="outOfDate"
              class="fa fa-warning text-warning"
              data-toggle="popover"
              data-trigger="hover"
              data-placement="bottom"
              data-html="true"
              :data-content="$t('old_version_alert')"
            ></span>
            <ReferenceConnectorSelector
              class="ref-connector-component"
              id="ref-connector-component"
              :refMap="refMap"
              :screenId="currentScreen.id"
              data-toggle="popover"
              data-trigger="manual"
              data-placement="bottom"
              :data-content="$t('ref_map_required')"
              @focus="hidePopoverMessage"
              :key="outOfDate"
            />
            <ScreenProcessArea
              :screenId="currentScreen.id"
              :processArea="currentScreen.process_area"
              :disabled="currentScreen.public"
              style="margin-left: 1em; margin-top: 0.2em"
              :key="+outOfDate"
            />
          </div>
        </div>
      </div>
      <div class="box-body no-padding box-main">
        <div class="row">
          <div class="col-lg-2 col-md-3 col-sm-4">
            <div class="box box-info layout-row">
              <div>
                <label style="margin-bottom: 0; width: 100%"
                  >{{ $tc("screen", 2) }}
                  <button
                    class="btn btn-xs"
                    style="float: right; position: relative"
                    :title="
                      !showSearch && isScreensFiltered
                        ? $t('titles.showing_search_results')
                        : $t('search')
                    "
                    @click="toggleSearch"
                  >
                    <i class="fa fa-search"></i>
                    <i
                      class="fa fa-circle icon-feedback"
                      v-if="!showSearch && isScreensFiltered"
                    ></i></button
                ></label>
                <div
                  :class="['search-wrapper', { 'search-hidden': !showSearch }]"
                >
                  <ListFilter
                    class="input-group-sm"
                    fields="name"
                    :list="screens"
                    :showSearchButton="false"
                    @filter="filteredScreens = $event"
                    ref="filter"
                  />
                </div>
              </div>
              <div class="box-body no-padding">
                <ul class="list">
                  <li
                    v-for="screen in filteredScreens"
                    :key="screen.id"
                    :class="[
                      'list-item',
                      { 'bg-info': selectedScreen == screen.id }
                    ]"
                    @click.stop.prevent="selectScreen(screen)"
                    :title="`#${screen.id} ${screen.description} (${
                      screen.revision_code || 'v0.00'
                    })`"
                    ref="screen"
                  >
                    <i
                      class="fa fa-globe privacy-icon"
                      v-if="screen.public"
                    ></i>
                    <i class="fa fa-briefcase privacy-icon" v-else></i>
                    <span class="screen-name">
                      <span v-bind:class="screen.id < 0 ? 'text-danger' : ''">{{
                        screenName(screen).replace(/[_+/]/g, " ")
                      }}</span>
                      <sup v-if="screen.id < 0">*</sup>
                    </span>
                    <div class="screen-options">
                      <span
                        class="btn btn-xs"
                        style="right: 22px"
                        v-if="
                          screen.id > 0 &&
                          !screen.public &&
                          $can('manage', 'TelaCustomizadaEscrita')
                        "
                        @click.stop.prevent="onVersions(screen)"
                        :title="$t('versions')"
                      >
                        <i class="fa fa-history"></i>
                      </span>
                      <span
                        class="btn btn-xs"
                        style="right: 22px"
                        v-if="
                          !screen.public &&
                          $can('manage', 'TelaCustomizadaEscrita')
                        "
                        @click.stop.prevent="onEdit(screen)"
                        :title="$t('edit')"
                      >
                        <i class="fa fa-pencil"></i>
                      </span>
                      <span
                        class="btn btn-xs"
                        style="right: 4px"
                        v-if="
                          !screen.public &&
                          $can('manage', 'TelaCustomizadaEscrita')
                        "
                        @click.stop.prevent="onRemove(screen)"
                        :title="$t('remove')"
                      >
                        <i class="fa fa-trash"></i>
                      </span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div
            class="col-lg-10 col-md-9 col-sm-8"
            v-if="selectedScreen && screensTemplates[selectedScreen]"
          >
            <DashboardLayoutEditor
              v-if="!busy"
              :screenId="selectedScreen"
              @refMapMissing="showPopoverMessage"
            />
          </div>
          <div
            class="col-lg-10 col-md-9 col-sm-8"
            v-else-if="selectedScreen && !busy"
          >
            <h3 class="centered text-danger">
              {{ $t("no_template") }}
            </h3>
          </div>
          <div class="col-lg-10 col-md-9 col-sm-8" v-else-if="busy">
            <h4 class="text-muted">
              {{ $t("loading") + "..." }}
            </h4>
          </div>
          <div class="col-lg-10 col-md-9 col-sm-8" v-else>
            <h4>
              {{ $t("select_a_screen_to_edit") }}
            </h4>
          </div>
        </div>
      </div>
    </div>
    <InfoBox v-else preset="unauthorized" />
    <PublishForm
      v-if="modal == 'publish'"
      v-on:close="modal = 'none'"
      v-on:saved="onSaved"
      v-bind:screenId="selectedScreen || ''"
      :isNew="isNew"
    />
    <UnpublishForm
      v-if="modal == 'unpublish'"
      v-on:close="modal = 'none'"
      v-on:removed="onRemoved"
      v-bind:screenId="selectedScreen || ''"
    />
    <SaveAsForm
      v-if="modal == 'saveas'"
      v-on:close="modal = 'none'"
      v-on:saved="onSaved"
      v-bind:screenId="selectedScreen || ''"
    />
    <ImportForm v-if="importForm" v-on:close="onImported" />
    <RestoreScreenDialog
      v-if="restoreScreenId"
      v-on:close="onCloseRestoreScreen($event)"
      :screenId="restoreScreenId"
    />
  </section>
</template>

<script>
import ReferenceConnectorSelector from "@/components/editor/reference-connector-selector";
import PublishForm from "@/components/editor/publish-form.vue";
import UnpublishForm from "@/components/editor/unpublish-form.vue";
import SaveAsForm from "@/components/editor/save-as-form.vue";
import InfoBox from "@/components/info-box.vue";
import ImportForm from "@/components/editor/import-form.vue";
import DashboardDownloadBtn from "@/components/editor/dashboard-download-btn.vue";
import ScreenProcessArea from "@/components/editor/screen-process-area";
import ListFilter from "@/components/widgets/list-filter";

import DashboardLayoutEditor from "@/components/editor/dashboard-layout-editor.vue";
import RestoreScreenDialog from "@/components/editor/restore-screen-dialog.vue";

import {
  validateTemplate,
  invalidTemplateAlert
} from "@/components/editor/publish-form.vue";

export default {
  name: "DashboardScreenLayoutEditor",
  components: {
    DashboardLayoutEditor,
    ReferenceConnectorSelector,
    PublishForm,
    UnpublishForm,
    SaveAsForm,
    InfoBox,
    ImportForm,
    DashboardDownloadBtn,
    ScreenProcessArea,
    ListFilter,
    RestoreScreenDialog
  },
  data() {
    let self = this;
    return {
      panels: {},
      screensLayouts: {},
      screensTemplates: {},
      loadingText: self.$t("loading") + "...",
      errorPanelName: self.$t("failed_to_load_panels"),
      modal: "none", // publish | unpublish | saveas
      isNew: false,
      busy: false,
      dataList: null,
      vuexModules: {
        SynopticPanel: "synoptic" // map here the $store module responsible for the panel
      },
      importForm: false,
      filteredScreens: [],
      showSearch: false,
      restoreScreenId: 0
    };
  },
  computed: {
    isEnabled() {
      if (this?.currentScreen) {
        if (!this?.currentScreen?.public) {
          return true;
        }
      }
      return false;
    },

    currentScreen() {
      return this.screens instanceof Array
        ? this.screens.find(({ id }) => id == this.selectedScreen)
        : null;
    },
    contract() {
      return this.$store.getters["user/contract"] || null;
    },
    screens() {
      return this.$store.getters["dashboard/screens"] || [];
    },
    selectedScreen() {
      return (this.$store.getters["dashboard/draft"] || { screenId: "" })
        ?.screenId;
    },
    refMap() {
      // get refMap from reference_connectors or template.ref_map
      return this.currentScreen?.reference_connectors instanceof Array
        ? this.currentScreen.reference_connectors.reduce((refMap, conn) => {
            refMap["conn" + (Object.keys(refMap).length + 1)] = conn.id;
            return refMap;
          }, {})
        : this.screensTemplates[this.selectedScreen]?.ref_map;
    },
    ETag() {
      return this?.template?.draft?.etag || null;
    },
    template() {
      return this.$store.getters["dashboard/editorTemplate"];
    },
    outOfDate() {
      return (
        this.currentScreen && this.ETag && this.ETag != this.currentScreen.etag
      );
    },
    isScreensFiltered() {
      return this.filteredScreens.length != this.screens.length;
    }
  },
  methods: {
    screenName(screen) {
      let name = screen.name;
      if (screen.id < 0) {
        name = this.$t("new_screen");
        name = `${name} (${
          -1 * screen.id <= 9 ? "0" + -1 * screen.id : -1 * screen.id
        })`;
      }
      return name;
    },
    onSaved(screen) {
      if (screen) {
        this.$store.dispatch("dashboard/removeDraft", screen.id);
        this.busy = true;
        this.selectScreen(screen);
        //this.updateEditingVersionStatus();
        this.$nextTick(() => {
          this.busy = false;
          let screenFiltered = this.filteredScreens.findIndex(
            (s) => s.id == screen.id
          );
          if (screenFiltered != -1) {
            this.$refs.screen[screenFiltered].scrollIntoViewIfNeeded();
          }
        });
      }
    },
    onImport() {
      this.$store.commit("dashboard/RESET_DRAFT");
      this.importForm = true;
    },
    onImported() {
      this.importForm = false;
      // this.busy = true;
      // this.$nextTick(() => {
      //   //this.busy = false;
      // });
    },
    onCreate() {
      //this.selectedScreen=null;
      this.$store.dispatch("dashboard/create");
    },
    async onEdit(screen) {
      if (screen) {
        //this.$router.push(`/dashboard/screen/view/${screen.id}`);
        await this.$store
          .dispatch("dashboard/initDraft", screen.id)
          .then((template) => {
            if (template) {
              this.$store.commit("dashboard/SET_TEMPLATE", {
                id: screen.id,
                data: template
              });
              this.$router.push(`/dashboard/screen/view/${screen.id}`);
            }
          });
      }
    },
    onRemoved(ix) {
      let screens = this.$store.getters["dashboard/screens"] || [];
      if (screens.length) {
        ix -= 1;
        if (ix < 0) ix = 0;
        if (ix >= 0 && ix < screens.length) {
          this.selectScreen(screens[ix]);
        }
      }
    },
    onRestore() {
      if (this.selectedScreen) {
        let wrapper = document.createElement("div");
        wrapper.innerHTML =
          this.$t("this_will_reset_all_of_your_unpushed_changes") +
          "<br>" +
          this.$t("you_wont_be_able_to_revert_this");
        this.$swal({
          title: this.$t("are_you_sure"),
          content: wrapper,
          icon: "warning",
          buttons: [this.$t("cancel"), this.$t("confirm")]
        }).then((option) => {
          if (option) {
            // todo: remove it after merge layout
            // this.$store.dispatch("dashboard/removeDraft", this.selectedScreen);
            // this.$store.dispatch("dashboard/removeEtag", this.selectedScreen);
            //this.fetchTemplate(this.selectedScreen);
            this.$store.dispatch("dashboard/restoreDraft", this.selectedScreen);
          }
        });
      }
    },
    screen() {
      // not sure why it does not work as computed property
      if (this.selectedScreen) {
        return this.$store.getters["dashboard/screen"](this.selectedScreen);
      }
      return null;
    },
    selectScreen({ id }) {
      if (this.selectedScreen != id) {
        //this.busy = true;
        this.$store.dispatch("dashboard/initDraft", id);
      }
    },
    onPublish(screen, isNew = false) {
      if (!validateTemplate(this.template)) {
        invalidTemplateAlert(this);
        return;
      }
      if (!isNew) this.selectScreen(screen);
      this.isNew = isNew;
      this.modal = "publish";
    },
    onSaveAs(screen) {
      this.selectScreen(screen);
      this.modal = "saveas";
    },
    onOpen() {
      if (this.template) {
        let connectorId = this.$store.getters["dashboard/screenRefMap"](
          this.selectedScreen
        )?.conn1;
        // if (!connectorId) {
        //   this.showPopoverMessage();
        //   return;
        // }
        this.$store.commit("dashboard/SET_TEMPLATE", {
          id: this.selectedScreen,
          data: this.template
        });
        this.$nextTick(() => {
          if (connectorId) {
            this.$router.push(
              `/dashboard/equipment/${connectorId}/${this.selectedScreen}`
            );
          } else {
            this.$router.push(
              `/dashboard/equipment/screen/${this.selectedScreen}`
            );
          }
        });
      }
    },
    onRemove(screen) {
      if (screen.public) return;
      // not published, just discharge it
      if (parseInt(screen.id) <= 0) {
        let screens = this.$store.getters["dashboard/screens"] || [];
        (this.panels[screen.id] || []).forEach((panel) => {
          if (panel.template in this.vuexModules) {
            this.$store.dispatch(
              `${this.vuexModules[panel.template]}/removeDraft`
            );
          }
        });
        let ix = screens.findIndex((i) => i.id == screen.id);
        this.$store.dispatch("dashboard/remove", screen.id);
        this.onRemoved(ix);
        return;
      }
      this.selectScreen(screen);
      this.modal = "unpublish";
    },
    onVersions(screen) {
      if (screen) {
        //this.selectScreen(screen.id);
        this.restoreScreenId = screen.id;
      }
    },
    fetchScreens() {
      if (this.contract) {
        let query = {
          contract_id: this.contract.id
        };
        this.fetched = false; // not reactive - important
        this.$store.dispatch("dashboard/fetchScreens", query);
      }
    },
    showPopoverMessage() {
      $("#ref-connector-component").popover("show");
      if ($("#ref-connector-component")[0].getBoundingClientRect().top < 0)
        $("#ref-connector-component")[0].scrollIntoView({ behavior: "smooth" });
      setTimeout(this.hidePopoverMessage, 3000);
    },
    hidePopoverMessage() {
      $("#ref-connector-component").popover("hide");
    },
    toggleSearch() {
      this.showSearch = !this.showSearch;
      if (this.showSearch) this.$refs.filter.$el.querySelector("input").focus();
    },
    onCloseRestoreScreen(goto_path) {
      this.restoreScreenId = 0;
      this.$forceUpdate();
      if (goto_path) {
        this.busy = true;
        setTimeout(
          () => {
            this.busy = false;
            this.$router.push(goto_path);
          },
          500,
          this
        );
      }
    }
    // updateEditingVersionStatus() {
    //   this.outOfDate =
    //     this.ETag && this.ETag != this.currentScreen.etag;
    // }
  },
  watch: {
    contract(n, o) {
      if (!o && n) {
        this.fetchScreens();
      }
    },
    template(n) {
      if (this.selectedScreen && n) {
        //this.busy = false;
        this.$set(this.panels, this.selectedScreen, n.panels);
        this.$set(this.screensLayouts, this.selectedScreen, n.layout);
        this.$set(this.screensTemplates, this.selectedScreen, n);
        let refMap = this.$store.getters["dashboard/screenRefMap"](n) || null;
        if (refMap) {
          this.$store.dispatch("fetchEquipmentDataList", {
            id: refMap.conn1
          });
        }
        this.busy = false;
        this.$nextTick(() => {
          // (re)initialize popovers that might have lost reference
          $(this.$el).find("[data-toggle=popover]").popover();
        });
        //this.updateEditingVersionStatus();
      }
    },
    screens: {
      handler(n, o) {
        if (!o && n) {
          n.forEach(
            ({ id }) => (this.panels[id] = [{ name: this.loadingText }])
          );
        }
        if (n && typeof this.fetched != "undefined") {
          this.fetched = true;
        }
        this.filteredScreens = (n ? [...n] : []).filter((i) => !i.public);
      },
      deep: true,
      immediate: true
    },
    refMap(n, o) {
      if (n) {
        if (o && o.conn1 == n.conn1) return;
        this.$store.dispatch("fetchEquipmentDataList", {
          id: n.conn1
        });
        this.$emit("equipmentChanged", n.conn1);
      }
    }
  },
  created() {
    this.$store.dispatch("fetchEquipmentList");
    this.$store.dispatch("dashboard/resetDraft");
    this.fetchScreens();
  },
  beforeDestroy() {
    this.$store.dispatch("history/reset");
    $(this.$el).find("[data-toggle=popover]").popover("destroy");

    // TODO: uncomment lines below after next update
    // if (typeof this.fetched != "undefined" && this.fetched) {
    //      this.$store.dispatch("dashboard/draftCleanUp"); // remove orphans
    // }
  },
  mounted() {
    this.$root.$emit("controlSidebar:setContent", null);
    this.$emit("equipmentChanged");
  }
};
</script>

<style scoped>
.wrapper-box {
  margin-bottom: 0;
}

.box.layout-row {
  margin-bottom: 0;
}

.box.layout-row > div.box-body {
  min-height: 200px;
}

.list {
  padding-left: 0;
  overflow-y: auto;
  overflow-x: hidden;
  height: auto;
  max-height: 80vh;
  margin-bottom: 0;
  scroll-behavior: smooth;
}

.list-item:not(:last-of-type) {
  border-bottom: none;
}

.list-item {
  list-style: none;
  border: 1px solid lightgray;
  border-radius: 2px;
  padding: 0.5rem;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
}

.list-tiem * {
  flex: 1;
}

.list-item:not(.bg-info):hover {
  background-color: whitesmoke;
}

.screen-options * {
  color: #333;
}

.screen-options *:hover {
  opacity: 0.8;
  color: rgb(133, 87, 1);
  cursor: pointer;
}

.list-item .privacy-icon {
  color: rgb(111 153 187);
  margin-right: 0.3em;
}

.screen-name {
  flex: 4;
}

span.screen-name span {
  vertical-align: text-bottom;
}

.toolbar .icons {
  display: inline-block;
}

.toolbar .icons span.btn-xs {
  margin-right: -3px;
}

.toolbar .icons span.btn-xs:hover {
  opacity: 0.8;
  color: rgb(133, 87, 1);
}

.ref-connector-component {
  display: inline-block;
  margin-left: 5px;
}

label {
  font-size: 14px;
  line-height: 1.42857143;
  color: #333;
  padding: 5px;
}

.centered {
  margin-left: 11em;
  margin-top: 2em;
}

#old-version-alert {
  font-size: 1.5em;
  margin-right: 1em;
  margin-left: 1em;
}

.search-wrapper {
  height: 30px;
  overflow: hidden;
  transition: height 150ms;
}

.search-wrapper.search-hidden {
  height: 0px;
}

.icon-feedback {
  color: #3c8dbc;
  position: absolute;
  top: -0.3rem;
  right: -0.3rem;
  font-size: 0.8rem;
}

section.container-fluid {
  /*
    prevent content "jump" when scrollbar is visibile 
    (https://aykevl.nl/2014/09/fix-jumping-scrollbar)
  */
  padding-right: calc(15px - (100vw - (100% + 60px)));
}

.search-wrapper::v-deep .form-control.full-width {
  width: calc(100% + 34px);
}
</style>
<style>
#old-version-alert ~ .popover {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faebcc;
}

#old-version-alert ~ .popover .arrow::after {
  border-bottom-color: #fcf8e3;
}

#old-version-alert ~ .popover .arrow {
  border-bottom-color: #faebcc;
}
</style>
