import { render, staticRenderFns } from "./save-as-form.vue?vue&type=template&id=220aef92&scoped=true&"
import script from "./save-as-form.vue?vue&type=script&lang=js&"
export * from "./save-as-form.vue?vue&type=script&lang=js&"
import style0 from "./save-as-form.vue?vue&type=style&index=0&id=220aef92&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "220aef92",
  null
  
)

export default component.exports